import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Program } from 'src/app/Models/program.model';
import { OrganizationAccessorService } from 'src/app/Services/organization-accessor.service';
import { PreferencesService } from 'src/app/Services/preferences.service';
import { ProgramAccessorService } from 'src/app/Services/program-accessor.service';
import { ThemeAccessorService } from 'src/app/Services/theme-accessor.service';
import { ProviderCategoryAccessorService } from 'src/app/Services/provider-category-accessor.service';
import { BreadcrumbService } from 'src/app/Services/breadcrumb.service';
import { Breadcrumb } from 'src/app/Models/breadcrumb.model';

@Component({
  selector: 'app-provider-category-view',
  templateUrl: './provider-category-view.component.html',
  styleUrls: ['./provider-category-view.component.sass']
})
export class ProviderCategoryViewComponent implements OnInit {
  categoryId: string;
  categoryName: string;
  programMap = {};
  otherPrograms: Program[];
  expandMisc = false;
  baseColor: string;

  constructor(
    private providerCategoryAccessor: ProviderCategoryAccessorService,
    private locationService: Location,
    private organizationAccessor: OrganizationAccessorService,
    private breadCrumbService: BreadcrumbService,
    private preferencesService: PreferencesService,
    private programAccessor: ProgramAccessorService,
    private route: Router,
    private themeAccessor: ThemeAccessorService
  ) {}

  getPrograms(subcategoryId: string) {
    return this.programAccessor.getProgramsByProviderCategoryid(this.categoryId, this.preferencesService.getLang());
  }

  ngOnInit(): void {
    this.route.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }

    this.themeAccessor.getThemeElement('baseColor').subscribe(element => {
      this.baseColor = element;
    });

    this.categoryId = this.locationService.path().split('/').slice(-1)[0];
    const categoryObservable = this.providerCategoryAccessor.getCategoryById(this.categoryId, this.preferencesService.getLang());
    categoryObservable.subscribe( category => {
      if (category) {
        this.categoryName = category.Name;
        this.breadCrumbService.addCrumb(new Breadcrumb(category.Name, `/for-providers/${this.categoryId}`, category.Icon));
      }
    });

    this.programAccessor
      .getProgramsWhere((program: Program) =>
        program.ProviderCategoryIds !== undefined && program.ProviderCategoryIds.includes(this.categoryId)).subscribe(programs => {
          const namedPrograms = programs.map((program) =>  {
            program.Summary =
              Program.getDisplayName(this.organizationAccessor, program, Number.MAX_VALUE, this.preferencesService.getLang());

              return program;
      });
      namedPrograms.sort((a, b) => a.Summary > b.Summary ? 1 : -1);
      this.otherPrograms = namedPrograms;
    });
  }
}
