import { Component, OnInit, Host } from '@angular/core';
import { Breadcrumb } from '../../Models/breadcrumb.model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subcategory } from 'src/app/Models/subcategory';
import { Program } from '../../Models/program.model';
import { Observable } from 'rxjs';
import { Result } from '../../Models/result.model';
import {OrganizationAccessorService} from '../../Services/organization-accessor.service';
import {ProgramAccessorService} from '../../Services/program-accessor.service';
import {SubcategoryAccessorService} from '../../Services/subcategory-accessor.service';
import {ThemeAccessorService} from '../../Services/theme-accessor.service';
import { PreferencesService } from 'src/app/Services/preferences.service';
import { BreadcrumbService } from 'src/app/Services/breadcrumb.service';


@Component({
  selector: 'app-subcategory-view',
  templateUrl: './subcategory-view.component.html',
  styleUrls: ['./subcategory-view.component.sass']
})
export class SubcategoryViewComponent implements OnInit {

  subcategoryObservable: Observable<Subcategory>;
  programs: Observable<Program[]>;
  programsTranslated: Observable<Program[]>;
  programResults: Result[] = [];
  // amt of characters results are trimmed to
  maxLength = 80;

  baseColor: string;
  constructor(
    private organizationAccessor: OrganizationAccessorService,
    private breadcrumbService: BreadcrumbService,
    private preferencesService: PreferencesService,
    private programAccessor: ProgramAccessorService,
    private subcategoryAccessor: SubcategoryAccessorService,
    private router: ActivatedRoute,
    private themeAccessor: ThemeAccessorService) {}

  ngOnInit() {
    // subscribes to current router url and pulls the category and subcategory ID
    this.router.params.subscribe(params => {
      const categoryId = params['id1'];
      const subcategoryId = params['id2'];
      const lang = this.preferencesService.getLang();
      // pulls reference of itself from database
      this.subcategoryObservable = this.subcategoryAccessor.getSubcategoryByIds(categoryId, subcategoryId);
      this.themeAccessor.getThemeElement('baseColor').subscribe(element => {
        this.baseColor = element;
      });
      // grabs translated version of itself
      this.subcategoryAccessor.getSubcategoryByIds(categoryId, subcategoryId, lang)
        .subscribe(translated => {
          this.subcategoryObservable.subscribe(subcategory => {
            // uses translated name of subcat if exists, otherwise uses english name
            if (translated) {
              this.breadcrumbService.addCrumb(new Breadcrumb(translated.Name, `/subcategory/${categoryId}/${subcategoryId}`));
            } else if (subcategory) {
              this.breadcrumbService.addCrumb(new Breadcrumb(subcategory.Name, `/subcategory/${categoryId}/${subcategoryId}`));
            }
          });
        });

      this.programs = this.programAccessor.getProgramsBySubcategory(subcategoryId, categoryId);
      this.programsTranslated = this.programAccessor.getProgramsBySubcategory(subcategoryId, categoryId, lang);

      this.programsTranslated.subscribe(translatedPrograms => {
        this.programs.subscribe(programs => {
          translatedPrograms.forEach(trans => {
            // appends any translated programs to program list
            const orgName = Program.getOrgName(this.organizationAccessor, trans, lang);
            const progName = Program.getDisplayName(this.organizationAccessor, trans, this.maxLength);
            this.programResults.push(
              { id: trans.key, name: `${orgName}: ${progName}`, info: '', isProgram: true }
            );
          });
          programs.forEach(program => {
            // appends untranslated programs to list if no translation present
            if (!this.programResults.some(e => e.id === program.key)) {
              const orgName = Program.getOrgName(this.organizationAccessor, program, lang);
              const progName = Program.getDisplayName(this.organizationAccessor, program, this.maxLength);
              // displays program as "Organization: Program" if org and prog name arent the same already
              if (orgName === progName) {
                this.programResults.push(
                  { id: program.key, name: `${progName}`, info: '', isProgram: true }
                );
              } else {
                this.programResults.push(
                  { id: program.key, name: `${orgName}: ${progName}`, info: '', isProgram: true }
                );
              }
            }
          });
          this.programResults.sort((a, b) => {
            const x = a.name.toLowerCase();
            const y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
        });
      });
    });
  }

}
