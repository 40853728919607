import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from '../environments/environment';
import { SearchBarComponent } from './Components/search-bar/search-bar.component';
import { DataListComponent } from './Components/data-list/data-list.component';
import { CategoriesViewComponent } from './Components/categories-view/categories-view.component';
import { AppRoutingModule } from './app.routing';
import { SubcategoryViewComponent } from './Components/subcategory-view/subcategory-view.component';
import { CategoryViewComponent } from './Components/category-view/category-view.component';
import { PdfComponent } from './Components/pdf/pdf.component';
import { HotlinesComponent } from './Components/hotlines/hotlines.component';
import { CalendarComponent } from './Components/calendar/calendar.component';
import { ProgramViewComponent } from './Components/program-view/program-view.component';
import { CalendarWidgetComponent } from './Components/calendar-widget/calendar-widget.component';
import { MapWidgetComponent } from './Components/map-widget/map-widget.component';
import { BreadcrumbComponent } from './Components/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfiniteListModule } from 'angular-infinite-list';
import { DetailViewComponent } from './Components/detail-view/detail-view.component';
import { OrganizationViewComponent } from './Components/organization-view/organization-view.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HttpClientModule } from '@angular/common/http';
import { SafePipe } from './Components/map-widget/Safe.Pipe';
import { FullMapViewComponent } from './Components/full-map-view/full-map-view.component';
import { AgmCoreModule } from '@agm/core';
import { GeocodeService } from './Services/geocode.service';
import { LandingPageComponent } from './Components/landing-page/landing-page.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TermsComponent } from './Components/terms/terms.component';
import { SetLanguageComponent } from './Components/set-language/set-language.component';
import { PrivacyPolicyComponent } from './Components/privacy-policy/privacy-policy.component';
import { ProgramRequestComponent } from './Components/program-request/program-request.component';
import { NewProgramListComponent } from './Components/new-program-list/new-program-list.component';

import { registerLocaleData } from '@angular/common';
import localAr from '@angular/common/locales/ar';
import localeEs from '@angular/common/locales/es';
import localSo from '@angular/common/locales/so';
import localVi from '@angular/common/locales/vi';
import localUk from '@angular/common/locales/uk';
import localPrs from '@angular/common/locales/fa-AF';
import localPs from '@angular/common/locales/ps';
import localKu from '@angular/common/locales/ckb';
import localKmr from '@angular/common/locales/ku';
import { ProviderCategoriesViewComponent } from './Components/provider-categories-view/provider-categories-view.component';
import { ProviderCategoryViewComponent } from './Components/provider-category-view/provider-category-view.component';
registerLocaleData(localAr, 'ar');
registerLocaleData(localeEs, 'es');
registerLocaleData(localSo, 'so');
registerLocaleData(localVi, 'vi');
registerLocaleData(localUk, 'uk');
registerLocaleData(localPrs, 'prs');
registerLocaleData(localPs, 'ps');
registerLocaleData(localKu, 'ku');
registerLocaleData(localKmr, 'kmr');

@NgModule({
  declarations: [
    AppComponent,
    SearchBarComponent,
    DataListComponent,
    CategoriesViewComponent,
    SubcategoryViewComponent,
    CategoryViewComponent,
    PdfComponent,
    HotlinesComponent,
    CalendarComponent,
    ProgramViewComponent,
    CalendarWidgetComponent,
    MapWidgetComponent,
    BreadcrumbComponent,
    DetailViewComponent,
    OrganizationViewComponent,
    SafePipe,
    FullMapViewComponent,
    LandingPageComponent,
    TermsComponent,
    SetLanguageComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    ProgramRequestComponent,
    NewProgramListComponent,
    ProviderCategoryViewComponent,
    ProviderCategoriesViewComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    HttpClientModule,
    InfiniteListModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatListModule,
    MatButtonToggleModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatSliderModule,
    MatToolbarModule,
    MatDialogModule,
    MatTableModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
    }),
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    FlexLayoutModule,
    ReactiveFormsModule,
  ],
  providers: [GeocodeService, MatDatepickerModule],
  bootstrap: [AppComponent],
  entryComponents: [ProgramRequestComponent],
})
export class AppModule {}
