<app-breadcrumb class="for-providers"></app-breadcrumb>
<div id="container">
  <mat-list class="result" *ngIf="otherPrograms" [ngStyle]="{'color': baseColor}">
    <mat-list-item class="programContainer" *ngFor="let program of otherPrograms" [queryParams]="{frmCats:'1'}">
      <a class="programText" [routerLink]="['/program', program.key]">
        {{(program.Summary || 'No name available')}}
      </a>
    </mat-list-item>
  </mat-list>
</div>
