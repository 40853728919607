import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { CategoriesViewComponent } from './Components/categories-view/categories-view.component';
import { CategoryViewComponent } from './Components/category-view/category-view.component';
import { SubcategoryViewComponent } from './Components/subcategory-view/subcategory-view.component';
import { HotlinesComponent } from './Components/hotlines/hotlines.component';
import { CalendarComponent } from './Components/calendar/calendar.component';
import { ProgramViewComponent } from './Components/program-view/program-view.component';
import { OrganizationViewComponent } from './Components/organization-view/organization-view.component';
import { FullMapViewComponent } from './Components/full-map-view/full-map-view.component';
import { LandingPageComponent } from './Components/landing-page/landing-page.component';
import { SetLanguageComponent } from './Components/set-language/set-language.component';
import { LanguageGuard } from './Services/language.guard';
import { PrivacyPolicyComponent } from './Components/privacy-policy/privacy-policy.component';
import { TermsComponent } from './Components/terms/terms.component';
import { NewProgramListComponent } from './Components/new-program-list/new-program-list.component';
import { ProviderCategoriesViewComponent } from './Components/provider-categories-view/provider-categories-view.component';
import { ProviderCategoryViewComponent } from './Components/provider-category-view/provider-category-view.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home',
  },
  {
    path: 'home',
    component: LandingPageComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'categories',
    component: CategoriesViewComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'category/:id',
    component: CategoryViewComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'subcategory/:id1/:id2',
    component: SubcategoryViewComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'program/:id',
    component: ProgramViewComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'organization/:id',
    component: OrganizationViewComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'hotlines',
    component: HotlinesComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'fullmap',
    component: FullMapViewComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'whats-new',
    component: NewProgramListComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-service',
    component: TermsComponent,
  },
  {
    path: 'set-language',
    component: SetLanguageComponent,
  },
  {
    path: 'for-providers',
    component: ProviderCategoriesViewComponent,
    canActivate: [LanguageGuard]
  },
  {
    path: 'for-providers/:id',
    component: ProviderCategoryViewComponent,
    canActivate: [LanguageGuard]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/home',
  },
  // ^ this one needs to be last, as routes below it will not work
  // seriously just dont mess with it. please. this is my one request to you.
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const routingComponents = [AppComponent, CategoriesViewComponent];
