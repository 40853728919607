import { Component, OnInit } from '@angular/core';
import { analytics, database } from 'firebase';
import { Observable } from 'rxjs';
import { Breadcrumb } from 'src/app/Models/breadcrumb.model';
import { ProviderCategory } from 'src/app/Models/provider-category';
import { BreadcrumbService } from 'src/app/Services/breadcrumb.service';
import { PreferencesService } from 'src/app/Services/preferences.service';
import { ProviderCategoryAccessorService } from 'src/app/Services/provider-category-accessor.service';
import { ThemeAccessorService } from 'src/app/Services/theme-accessor.service';

@Component({
  selector: 'app-provider-categories-view',
  templateUrl: './provider-categories-view.component.html',
  styleUrls: ['./provider-categories-view.component.sass'],
})
export class ProviderCategoriesViewComponent implements OnInit {
  baseColor: string;
  public categories: Observable<ProviderCategory[]>;

  showLogoGrid: boolean;

  constructor(
    private breadCrumbService: BreadcrumbService,
    private preferencesService: PreferencesService,
    private providerCategoryAccessor: ProviderCategoryAccessorService,
    private themeAccessor: ThemeAccessorService
  ) {}

  async ngOnInit() {

    this.categories = this.providerCategoryAccessor.getCategories(
      this.preferencesService.getLang()
    );

    this.themeAccessor.getThemeElement('baseColor').subscribe((element) => {
      this.baseColor = element;
    });

    switch (this.preferencesService.getLang()) {
      case 'vi':
        this.breadCrumbService.addCrumb(new Breadcrumb('Trang chủ', '/for-providers'));
        break;
      case 'es':
        this.breadCrumbService.addCrumb(new Breadcrumb('Para proveedores', '/for-providers'));
        break;
      case 'ar':
        this.breadCrumbService.addCrumb(new Breadcrumb('لمقدمي الخدمات', '/for-providers'));
        break;
      case 'prs':
        this.breadCrumbService.addCrumb(new Breadcrumb('برای ارائه دهندگان', '/for-providers'));
        break;
      case 'ps':
        this.breadCrumbService.addCrumb(new Breadcrumb('د چمتو کونکو لپاره', '/for-providers'));
        break;
      case 'ku':
        this.breadCrumbService.addCrumb(new Breadcrumb('بۆ دابینکەران', '/for-providers'));
        break;
      case 'kmr':
        this.breadCrumbService.addCrumb(new Breadcrumb('Ji bo Pêşkêşkeran', '/for-providers'));
        break;
      case 'so':
        this.breadCrumbService.addCrumb(new Breadcrumb('Loogu talagalay Bixiyeyaasha', '/for-providers'));
        break;
      case 'uk':
        this.breadCrumbService.addCrumb(new Breadcrumb('Для провайдерів', '/for-providers'));
        break;
      case 'vi':
        this.breadCrumbService.addCrumb(new Breadcrumb('Dành cho nhà cung cấp', '/for-providers'));
        break;
      default:
        this.breadCrumbService.addCrumb(new Breadcrumb('For Providers', '/for-providers'));
    }
  }

  onCategorySelect(categoryKey: string, categoryName: string) {
    analytics().logEvent('provider_category_selected', { categoryKey, categoryName });
    const ref = database().ref('providerCategorySelections');
    ref.push({ categoryKey, categoryName, timestamp: Date.now() });
  }
}
