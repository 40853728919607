<div *ngIf="categories | async as categoriesList" id="container">
  <div *ngFor="let category of categoriesList">
    <a [routerLink]="['/for-providers', category.key]" (click)="onCategorySelect(category.key, category.Name)">
      <div class="category" [ngStyle]="{'color': baseColor}">
        <i class="material-icons">{{category.Icon?.toLowerCase()}}</i>
        <br>
        <p class="name">{{category.Name}}</p>
      </div>
    </a>
  </div>
</div>
<div *ngIf="showLogoGrid" class="logo-grid">
  <div class="logo-container">
    <div class="logo-item">
      <img src="../assets/images/col.svg" alt="City of Lincoln Nebraska logo">
    </div>
    <div class="logo-item">
      <img src="../assets/images/dpl.svg" alt="Don't Panic Labs logo">
    </div>
    <div class="logo-item">
      <img src="../assets/images/ll.svg" alt="Leadership Lincoln logo">
    </div>
    <div class="logo-item">
      <img src="../assets/images/cfpin.svg" alt="Center for People in Need logo">
    </div>
  </div>
</div>
