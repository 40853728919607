import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '../../Models/breadcrumb.model';
import { Router } from '@angular/router';
import { Subcategory } from 'src/app/Models/subcategory';
import { Program } from '../../Models/program.model';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationAccessorService } from '../../Services/organization-accessor.service';
import { CategoryAccessorService } from '../../Services/category-accessor.service';
import { ProgramAccessorService } from '../../Services/program-accessor.service';
import { SubcategoryAccessorService } from '../../Services/subcategory-accessor.service';
import { ThemeAccessorService } from '../../Services/theme-accessor.service';
import { PreferencesService } from 'src/app/Services/preferences.service';
import { BreadcrumbService } from 'src/app/Services/breadcrumb.service';
import { analytics, database } from 'firebase/app';

@Component({
  selector: 'app-category-view',
  templateUrl: './category-view.component.html',
  styleUrls: ['./category-view.component.sass']
})
export class CategoryViewComponent implements OnInit {
  categoryId: string;
  categoryName: string;
  subcategoriesCombined: Observable<Subcategory[]>;
  programMap = {};
  otherPrograms: Program[];
  expandMisc = false;
  baseColor: string;

  constructor(
    private categoryAccessor: CategoryAccessorService,
    private locationService: Location,
    private organizationAccessor: OrganizationAccessorService,
    private breadCrumbService: BreadcrumbService,
    private preferencesService: PreferencesService,
    private programAccessor: ProgramAccessorService,
    private route: Router,
    private subcategoryAccessor: SubcategoryAccessorService,
    private themeAccessor: ThemeAccessorService
  ) {}

  getPrograms(subcategoryId: string) {
    return this.programAccessor.getProgramsBySubcategory(subcategoryId, this.categoryId, this.preferencesService.getLang());
  }

  ngOnInit() {
    this.route.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }

    this.themeAccessor.getThemeElement('baseColor').subscribe(element => {
      this.baseColor = element;
    });
    // gets category key from URL
    this.categoryId = this.locationService.path().split('/').slice(-1)[0];
    const categoryObservable = this.categoryAccessor.getCategoryById(this.categoryId, this.preferencesService.getLang());
    categoryObservable.subscribe( category => {
      if (category) {
        this.categoryName = category.Name;
        this.breadCrumbService.addCrumb(new Breadcrumb(category.Name, `/category/${this.categoryId}`, category.Icon));
      }
    });

    const englishSubcategories = this.subcategoryAccessor.getSubcategoriesById(this.categoryId);

    if (this.preferencesService.getLang() === 'en') {
      this.subcategoriesCombined = englishSubcategories.pipe(map(subcategories => subcategories.sort((a, b) => a.Name > b.Name ? 1 : -1)));
      // Put 'Other' subcategory at the end of the list
      this.subcategoriesCombined = this.subcategoriesCombined.pipe(map(subcategories => {
        const indexOfOther = subcategories.map(x => x.Name).indexOf('Other');
        if (indexOfOther !== -1) {
          subcategories.push(subcategories[indexOfOther]);
          subcategories.splice(indexOfOther, 1);
        }

        subcategories.forEach(subcategory => {
          this.getPrograms(subcategory.key).subscribe(programs => {
            const namedPrograms = programs.map((program) =>  {
              program.Summary =
                Program.getDisplayName(this.organizationAccessor, program, Number.MAX_VALUE, this.preferencesService.getLang());

              return program;
            });
            namedPrograms.sort((a, b) => a.Summary > b.Summary ? 1 : -1);
            this.programMap[subcategory.key] = namedPrograms;
          });
        });

        // Expand miscellaneous tab if there are no other subcategories
        this.expandMisc = !subcategories.length;

        return subcategories;
      }));
    } else {
      // For each subcategory missing in the translations, add the english version
      englishSubcategories.subscribe(subcategories => {
        this.subcategoryAccessor.getSubcategoriesById(this.categoryId, this.preferencesService.getLang()).subscribe(translations => {
          this.subcategoriesCombined = new Observable(observer => {
            subcategories.sort((a, b) => a.Name > b.Name ? 1 : -1);
            subcategories.forEach(subcategory => {
              if (!translations.some(holder => holder.key === subcategory.key)) {
                translations.push(subcategory);
              }
            });

            // Set program Summary and sort alphabetically
            translations.forEach(subcategory => {
              this.getPrograms(subcategory.key).subscribe(programs => {
                const namedPrograms = programs.map((program) =>  {
                  program.Summary =
                    Program.getDisplayName(this.organizationAccessor, program, Number.MAX_VALUE, this.preferencesService.getLang());

                  return program;
                });
                namedPrograms.sort((a, b) => a.Summary > b.Summary ? 1 : -1);
                this.programMap[subcategory.key] = namedPrograms;
              });
            });

            // Expand miscellaneous tab if there are no other subcategories
            this.expandMisc = !translations.length;

            observer.next(translations);
          });
        });
      });
    }

    this.programAccessor
      .getProgramsWhere((program: Program) =>
        program.CategoryId === this.categoryId && !program.SubcategoryId && !program.SubcategoryIds).subscribe(programs => {
          const namedPrograms = programs.map((program) =>  {
            program.Summary =
              Program.getDisplayName(this.organizationAccessor, program, Number.MAX_VALUE, this.preferencesService.getLang());

              return program;
      });
      namedPrograms.sort((a, b) => a.Summary > b.Summary ? 1 : -1);
      this.otherPrograms = namedPrograms;
    });
  }

  onSubcategorySelect(subcategoryKey: string, subcategoryName: string) {
    analytics().logEvent('subcategory_selected', { subcategoryKey, subcategoryName });
    const ref = database().ref('subcategorySelections');
    ref.push({ subcategoryKey, subcategoryName, timestamp: Date.now() });
  }
}
