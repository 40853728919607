<body *ngIf="baseColor" [ngStyle]="{ 'background-color': baseColor }">
  <div class="appBanner" *ngIf="apple && dbLoaded && expandedHeader">
    <div class="i8">
      MyLink <br /><span class="i5">Leadership Lincoln</span>
      <p class="i4">{{ menuText?.appStore }}</p>
    </div>
    <img class="i3" src="{{ appstoreImage }}" alt="App Store image" />
    <a
      target="_blank"
      href="https://itunes.apple.com/us/app/mylnk/id1336646522?mt=8"
      class="i14"
      ><span class="i7">VIEW</span></a
    >
    <button class="i6" (click)="apple = false">&#10006;</button>
  </div>
  <div class="appBanner" *ngIf="android && dbLoaded && expandedHeader">
    <div class="i8">
      MyLink <br /><span class="i5">Leadership Lincoln</span>
      <p class="i4">{{ menuText?.playStore }}</p>
    </div>
    <img class="i3" src="{{ googlePlayImage }}" alt="Google Play image" />
    <a
      target="_blank"
      href="https://play.google.com/store/apps/details?id=org.leadershiplincoln.mylnk&hl=en_US"
      class="i14"
      ><span class="i7">VIEW</span></a
    >
    <button class="i6" (click)="android = false">&#10006;</button>
  </div>
  <div>
    <mat-toolbar-row
      [ngStyle]="{ 'background-color': baseColor }"
      *ngIf="!landingPage && hasLanguageSet"
    >
      <div id="tabLinks">
        <mat-toolbar-row id="nav">
          <img routerLink="/home" src="{{ appLogo }}" alt="MyLink logo" />
          <a routerLink="/categories" routerLinkActive="active_route">{{
            menuText?.navCategory
          }}</a>
          <a routerLink="/category/{{ hotlineId }}" routerLinkActive="active_route">{{
            menuText?.sidemenuHotlines
          }}</a>
          <a routerLink="/calendar" routerLinkActive="active_route">{{
            menuText?.navCalendar
          }}</a>
          <a routerLink="/fullmap" routerLinkActive="active_route">{{
            menuText?.navMap
          }}</a>
          <a routerLink="/whats-new" routerLinkActive="active_route">{{
            menuText?.navWhatsNew
          }}</a>
          <a routerLink="/for-providers" routerLinkActive="active_route">{{
            menuText?.navForProviders
          }}</a>
          <div>
            <mat-toolbar-row id="lang">
              <div>
                <a [routerLink]="" (click)="setLanguage()">{{
                  menuText?.setLanguage }}</a>
              </div>
            </mat-toolbar-row>
          </div>
        </mat-toolbar-row>
      </div>
      <div id="menuIcon">
        <i class="material-icons" (click)="sidenav.toggle()">menu</i>
        <img
          *ngIf="!expandedHeader"
          class="miniLogo"
          routerLink="/home"
          src="{{ appLogo }}"
          alt="MyLink logo"
        />
      </div>
    </mat-toolbar-row>
    <mat-sidenav-container fxFlexFill>
      <mat-sidenav #sidenav fxLayout="column">
        <mat-nav-list>
          <a mat-list-item routerLink="/categories">{{
            menuText?.navCategory
          }}</a>
          <a mat-list-item routerLink="/hotlines">{{
            menuText?.sidemenuHotlines
          }}</a>
          <a mat-list-item routerLink="/calendar">{{
            menuText?.navCalendar
          }}</a>
          <a mat-list-item routerLink="/fullmap">{{ menuText?.navMap }}</a>
          <a mat-list-item routerLink="/whats-new">{{
            menuText?.navWhatsNew
          }}</a>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content fxFlexFill>
        <mat-toolbar-row
          *ngIf="!landingPage && hasLanguageSet"
          [ngStyle]="{ 'background-color': baseColor }"
          id="minLang"
        >
          <div>
            <a [routerLink]="" (click)="setLanguage()">{{
              menuText?.setLanguage }}</a>
          </div>
        </mat-toolbar-row>
        <header
          [ngStyle]="{ 'background-color': baseColor }"
          *ngIf="hasLanguageSet"
        >
          <div *ngIf="expandedHeader" id="title">
            <img src="{{ appLogo }}" alt="MyLink logo" />
            <h2>{{ this.menuText?.subtitle }} : {{ location }}</h2>
          </div>
          <app-search-bar
            *ngIf="expandedHeader && !landingPage"
            style="margin-top: 290px;"
          ></app-search-bar>
          <app-search-bar
            *ngIf="!expandedHeader && !landingPage"
            style="margin-top: 140px;"
          ></app-search-bar>
        </header>
        <div id="content">
          <router-outlet (activate)="showBreadcrumbs = false"></router-outlet>
          <mat-toolbar
            [ngStyle]="{ 'background-color': baseColor }"
            id="footer"
            *ngIf="!landingPage"
          >
            <mat-toolbar-row>
              <p id="copyright">© {{ copyrightYear }} MyLink, LLC</p>
              <span class="toolbar-spacer"></span>
              <div *ngIf="!apple && !android">
                <a
                  class="app-download"
                  id="apple"
                  href="{{ appStoreLink }}"
                  aria-label="View MyLink app on iTunes"
                ></a>
                <a
                  class="app-download"
                  href="{{ googlePlayLink }}"
                  target="_blank"
                  aria-label="View MyLink app on Google Play"
                  ><img src="../assets/images/Get_on_google_play.png" alt="Google Play logo"
                /></a>
              </div>
              <span class="toolbar-spacer"></span>
              <p><a class="link" href="/privacy-policy">{{privacyText}}</a></p>
              <!-- <p><a class="link" href="/terms-service">Terms</a></p> -->
              <p>
                <a class="link" (click)="openDialog()">
                  {{requestText}}
                </a>
              </p>
            </mat-toolbar-row>
          </mat-toolbar>
        </div>
        <span
          [ngStyle]="{ 'background-color': baseColor }"
          id="bottom-filler"
          *ngIf="!landingPage"
        ></span>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</body>
