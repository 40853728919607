import { Component, Host, OnInit } from '@angular/core';
import { Subcategory } from 'src/app/Models/subcategory';
import { Program } from '../../Models/program.model';
import { Observable } from 'rxjs';
import { Result } from '../../Models/result.model';
import { Breadcrumb } from '../../Models/breadcrumb.model';
import {OrganizationAccessorService} from '../../Services/organization-accessor.service';
import {ProgramAccessorService} from '../../Services/program-accessor.service';
import {SubcategoryAccessorService} from '../../Services/subcategory-accessor.service';
import {ThemeAccessorService} from '../../Services/theme-accessor.service';
import { PreferencesService } from 'src/app/Services/preferences.service';
import { BreadcrumbService } from 'src/app/Services/breadcrumb.service';

@Component({
  selector: 'app-hotlines',
  templateUrl: './hotlines.component.html',
  styleUrls: ['./hotlines.component.sass']
})
export class HotlinesComponent implements OnInit {

  lang: string;
  subcategories: Subcategory[] = [];
  catId = `15`;
  maxLength = 60;
  hotlinePrograms: Observable<Program[]>;
  hotlineProgramsAdjusted: Result[] = [];
  translatedTags =
                [['Hotlines'],
                ['Đường dây nóng'],
                ['Líneas directas'],
                ['خطوط ساخنة'],
                ['Гарячі лінії'],
                ['خطوط تلفن'],
                ['هټ لاینونه'],
                ['هێڵە گەرمەکان'],
                ['Hotlines']];
  translationSet = [];

  baseColor: string;
  hotlineImage: string;
  constructor(
    private organizationAccessor: OrganizationAccessorService,
    private preferencesService: PreferencesService,
    private breadcrumbService: BreadcrumbService,
    private programAccessor: ProgramAccessorService,
    private subcategoryAccessor: SubcategoryAccessorService,
    private themeAccessor: ThemeAccessorService) {}

  private getDisplayValues(program: Program): Result {
    let programResults: Result;

    programResults = {
      id: program.key,
      name: Program.getDisplayName(this.organizationAccessor, program, this.maxLength, this.preferencesService.getLang()),
      info: program.Call,
      isProgram: true,
      subCategoryId: program.SubcategoryId || '3'
    };

    return programResults;
  }

  public compare(a, b) {
    const name1 = a.name.toUpperCase();
    const name2 = b.name.toUpperCase();

    return name1.localeCompare(name2);
  }

  ngOnInit() {
    this.lang = this.preferencesService.getLang();
    switch (this.lang) {
      case 'vi':
        this.translationSet = this.translatedTags[1];
        break;
      case 'es':
      this.translationSet = this.translatedTags[2];
        break;
      case 'ar':
      this.translationSet = this.translatedTags[3];
        break;
      case 'uk':
        this.translationSet = this.translatedTags[4];
        break;
      case 'prs':
        this.translationSet = this.translatedTags[5];
        break;
      case 'ps':
        this.translationSet = this.translatedTags[6];
        break;
      case 'ku':
        this.translationSet = this.translatedTags[7];
        break;
      case 'kmr':
        this.translationSet = this.translatedTags[8];
        break;
      default:
        this.translationSet = this.translatedTags[0];
    }

    this.breadcrumbService.addCrumb(new Breadcrumb(this.translationSet[0], '/hotlines'));

    this.subcategoryAccessor.getSubcategoriesById(this.catId, this.lang).subscribe(next => {
      this.subcategories = next;
    });

    this.hotlinePrograms = this.programAccessor.getProgramsByCategoryId(this.catId, this.lang);

    this.hotlinePrograms.subscribe(programs => {
      programs.forEach(program => this.hotlineProgramsAdjusted.push(this.getDisplayValues(program)));
      this.hotlineProgramsAdjusted.sort(this.compare);
    });

    this.themeAccessor.getThemeElement('baseColor').subscribe(element => {
      this.baseColor = element;
    });

    this.themeAccessor.getThemeElement('211Image').subscribe(element => {
      this.hotlineImage = element;
    });
   }

}
